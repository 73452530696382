// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManualAlertGroup-module__uKDi1DSBcr22JdOsPC0w {\n  display: flex;\n}\n\n.ManualAlertGroup-module__MB4v4WQ_3dhsrj0wf9ap {\n  background: var(--secondary-background);\n  width: 100%;\n}\n\n.ManualAlertGroup-module__jFo3qTxVmiH7KvgrAACK {\n  list-style-type: none;\n  margin-bottom: 20px;\n  width: 100%;\n  background: var(--background-secondary);\n\n  & > li .ManualAlertGroup-module__NnmkavyUGNqmvUd68U0Q {\n    display: none;\n  }\n\n  & > li:hover .ManualAlertGroup-module__NnmkavyUGNqmvUd68U0Q {\n    display: inline-flex;\n  }\n\n  & > li {\n    padding: 10px 12px;\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://./components/ManualAlertGroup/ManualAlertGroup.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,uCAAuC;EACvC,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,WAAW;EACX,uCAAuC;;EAEvC;IACE,aAAa;EACf;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;AACF","sourcesContent":[".assign-responders-button {\n  display: flex;\n}\n\n.info-block {\n  background: var(--secondary-background);\n  width: 100%;\n}\n\n.responders-list {\n  list-style-type: none;\n  margin-bottom: 20px;\n  width: 100%;\n  background: var(--background-secondary);\n\n  & > li .hover-button {\n    display: none;\n  }\n\n  & > li:hover .hover-button {\n    display: inline-flex;\n  }\n\n  & > li {\n    padding: 10px 12px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"assign-responders-button": "ManualAlertGroup-module__uKDi1DSBcr22JdOsPC0w",
	"info-block": "ManualAlertGroup-module__MB4v4WQ_3dhsrj0wf9ap",
	"responders-list": "ManualAlertGroup-module__jFo3qTxVmiH7KvgrAACK",
	"hover-button": "ManualAlertGroup-module__NnmkavyUGNqmvUd68U0Q"
};
export default ___CSS_LOADER_EXPORT___;
