// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhook2Form-module__J3mU_SpdXP_dmKjgiaYc {\n  display: block;\n}\n\n.OutgoingWebhook2Form-module__gjLtAwWezZFlIee9W9xO {\n  margin: 16px 0 0 16px;\n}\n\n.OutgoingWebhook2Form-module__fol7l0VXOW1PlITZm92S {\n  margin: 4px;\n}\n\n.OutgoingWebhook2Form-module__FBMGNcCmcMVxEiGGXIaV {\n  padding-top: 16px;\n}\n\n.OutgoingWebhook2Form-module__no5fgvtqg_tMxfmhOVAX {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 4px;\n}\n\n.OutgoingWebhook2Form-module__mI7peh65RqhY3QTDicUt {\n  flex-grow: 1;\n}\n\n/* TODO: figure out why this is not picked */\n.OutgoingWebhook2Form-module__Qagzqfjc9JkNPyv8l2lT .OutgoingWebhook2Form-module__HTG5DMHJnNrrca2PjiWx.OutgoingWebhook2Form-module__cRGJkEJ8BrSsEX8tBdXR {\n  display: none !important;\n}\n", "",{"version":3,"sources":["webpack://./containers/OutgoingWebhook2Form/OutgoingWebhook2Form.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,QAAQ;AACV;;AAEA;EACE,YAAY;AACd;;AAEA,4CAA4C;AAC5C;EACE,wBAAwB;AAC1B","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px;\n}\n\n.tabs__content {\n  padding-top: 16px;\n}\n\n.form-row {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 4px;\n}\n\n.form-field {\n  flex-grow: 1;\n}\n\n/* TODO: figure out why this is not picked */\n.webhooks__drawerContent .cursor.monaco-mouse-cursor-text {\n  display: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OutgoingWebhook2Form-module__J3mU_SpdXP_dmKjgiaYc",
	"title": "OutgoingWebhook2Form-module__gjLtAwWezZFlIee9W9xO",
	"content": "OutgoingWebhook2Form-module__fol7l0VXOW1PlITZm92S",
	"tabs__content": "OutgoingWebhook2Form-module__FBMGNcCmcMVxEiGGXIaV",
	"form-row": "OutgoingWebhook2Form-module__no5fgvtqg_tMxfmhOVAX",
	"form-field": "OutgoingWebhook2Form-module__mI7peh65RqhY3QTDicUt",
	"webhooks__drawerContent": "OutgoingWebhook2Form-module__Qagzqfjc9JkNPyv8l2lT",
	"cursor": "OutgoingWebhook2Form-module__HTG5DMHJnNrrca2PjiWx",
	"monaco-mouse-cursor-text": "OutgoingWebhook2Form-module__cRGJkEJ8BrSsEX8tBdXR"
};
export default ___CSS_LOADER_EXPORT___;
